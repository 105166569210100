import "./newProduct.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { doc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import CategoryCombobox from "./CategoryCombobox";
import * as React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ProductImages from "./ProductImages";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const NewProduct = ({ inputs, title }) => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const productId = URL[URL.length - 1];

  const [productData, setProductData] = useState([]);

  const [file, setFile] = useState("");
  const [downloadedImageUrl, setdownloadedImageUrl] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const navigate = useNavigate();
  const [onSaleChecker, setOnSaleChecker] = useState(false);
  const [dollarChecker, setDollarChecker] = useState(false);
  const [description, setDescription] = useState("");
  const [productimages, setProductImages] = useState([]);
  const [updatedImages, setUpdatedImages] = useState([]);
  // const [productSize, setProductSize] = useState("");
  const [productAdvertise, setProductAdvertise] = useState("");

  const [newSize, setNewSize] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newSale, setNewSale] = useState("");
  const [sizes, setSizes] = useState([]);

  function handleDeleteImage(index) {
    const newImages = [...productimages];
    newImages.splice(index, 1);

    setProductImages(newImages);
    setUpdatedImages(newImages);
  }

  function ImageCard({ src, alt, onDelete }) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{ width: "300px", height: "200px", objectFit: "cover" }}
        />
        <button
          onClick={onDelete}
          style={{
            marginTop: "10px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "5px 10px",
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  const updateDataImage = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "products", productId), {
        productImages: updatedImages,
      });
      alert("Images Updated!");
    } catch (err) {
      console.log(err);
    }
  };

  // console.log("UpdatedOut: ", productimages);
  const handleSaleChange = (event) => {
    setOnSaleChecker(event.target.checked);
  };
  const handleDollarChange = (event) => {
    setDollarChecker(event.target.checked);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      const productDataFire = query(
        collection(db, "products"),
        where("id", "==", productId)
      );

      const querySnapshot = await getDocs(productDataFire);
      querySnapshot.forEach((doc) => {
        setProductData({ id: doc.id, ...doc.data() });
        setCategoryId(doc.data().productCategoryName);
        setCategoryIdFun(doc.data().productCategoryName);
        setOnSaleChecker(doc.data().isOnSale);
        setDollarChecker(doc.data().isDollar);
        setDescription(doc.data().description);
        setProductImages(doc.data().productImages);
        // setProductSize(doc.data().productSize);
        setProductAdvertise(doc.data().productAdvertise);
        setSizes(doc.data().productSizePrice);
      });
    };
    fetchProduct();
  }, [productId]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const newSizes = [
      ...sizes,
      { size: newSize, price: newPrice, sale: newSale },
    ];
    setSizes(newSizes);

    setNewSize("");
    setNewPrice("");
    setNewSale("");
  };

  function handleDeleteSizes(index) {
    const updatedSizes = [...sizes];
    updatedSizes.splice(index, 1);

    setSizes(updatedSizes);
  }
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, "productsImages/" + name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, imageUrl: downloadURL }));
            setdownloadedImageUrl(downloadURL);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  // console.log(data);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value.toLowerCase();

    setData({ ...data, [id]: value });
  };
  // const productUid = v4();

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "products", productData.id), {
        ...data,
        productCategoryName: categoryId,
        isOnSale: onSaleChecker,
        isDollar: dollarChecker,
        description: description,
        // productSize: productSize,
        productAdvertise: productAdvertise,
        productSizePrice: sizes,
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };
  const setCategoryIdFun = (id) => {
    setCategoryId(id);
    // console.log("SelectCat onSet: ", id);
  };
  // console.log("productData: ", productimages);
  // console.log("productSize: ", productSize);
  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {/* <Navbar /> */}
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              <img
                src={file ? downloadedImageUrl : productData.imageUrl}
                // src={file ? URL.createObjectURL(file) : productData.imageUrl}
                alt=""
              />
              <Box>
                <form onSubmit={handleSubmit}>
                  <label>
                    Size:
                    <input
                      type="text"
                      value={newSize}
                      placeholder="100ML"
                      required
                      onChange={(event) => setNewSize(event.target.value)}
                    />
                  </label>
                  <label>
                    Price:
                    <input
                      type="text"
                      value={newPrice}
                      placeholder="100"
                      required
                      onChange={(event) => setNewPrice(event.target.value)}
                    />
                  </label>
                  <label>
                    Sale:
                    <input
                      type="text"
                      value={newSale}
                      placeholder="80"
                      required
                      onChange={(event) => setNewSale(event.target.value)}
                    />
                  </label>
                  <button type="submit">Add Size Price</button>
                </form>
                <ul>
                  {sizes.map((size, index) => (
                    <li key={index}>
                      {size.size} - {size.price} - {size.sale}
                      <div
                        onClick={() => handleDeleteSizes(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIcon />
                      </div>
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
            <div className="right">
              <form onSubmit={handleAdd}>
                <div className="formInput">
                  <label htmlFor="file">
                    Choose Image:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />

                  <div className="formInput">
                    <label htmlFor="categoryId">Category</label>
                    <input
                      id="categoryId"
                      type="text"
                      value={categoryId}
                      placeholder="Category"
                      readOnly={true}
                    />
                  </div>

                  {/* <div className="mt-2">
                    <label htmlFor="categoryId">Category: {categoryId}</label>
                    <div className="comboboxCell">
                      <CategoryCombobox
                        className="my-combo-box"
                        setCategoryIdFun={setCategoryIdFun}
                        tableFire="categories"
                      />
                    </div>
                  </div> */}
                </div>

                {inputs.map((input) => {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <input
                        id={input.id}
                        type={input.type}
                        placeholder={input.placeholder}
                        step={input.step}
                        onChange={handleInput}
                        required={input.required ? true : false}
                        readOnly={!input.readOnly ? false : true}
                        defaultValue={productData[input.id]}
                      />
                    </div>
                  );
                })}
                {/* <TextField
                  className="formInput"
                  id="productSize"
                  key="productSize"
                  label="Size"
                  type="text"
                  placeholder="S, M, L, XL"
                  value={productSize}
                  onChange={(e) => setProductSize(e.target.value)}
                /> */}
                <TextField
                  className="formInput"
                  id="productAdvertise"
                  key="productAdvertise"
                  label="Advertisement"
                  type="text"
                  placeholder="Buy 3 get 1 free"
                  value={productAdvertise}
                  onChange={(e) => setProductAdvertise(e.target.value)}
                />
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={8}
                  cols={40}
                  placeholder="Product Description"
                />

                <FormControlLabel
                  className="formInput"
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={onSaleChecker}
                      onChange={handleSaleChange}
                      defaultValue={productData.isOnSale}
                    />
                  }
                  label="On Sale"
                  labelPlacement="start"
                />
                <FormControlLabel
                  className="formInput"
                  value="end"
                  control={
                    <Switch
                      color="primary"
                      checked={dollarChecker}
                      onChange={handleDollarChange}
                    />
                  }
                  label="$"
                  labelPlacement="end"
                />

                <div className="sendButton">
                  <button disabled={per !== null && per < 100} type="submit">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {productimages.map((productimage, index) => (
          <ImageCard
            key={index}
            src={productimage}
            alt={index}
            onDelete={() => handleDeleteImage(index)}
          />
        ))}

        <div className="updateButton">
          <button onClick={updateDataImage}>Update Images</button>
        </div>
      </div>

      {/* <div className="imagesProduct">
        {productimages.map((productimage, index) => (
          <div key={index}>
            <img src={productimage} alt="Updated" />
            <button onClick={() => handleDeleteImage(index)}>Delete</button>
          </div>
        ))}
        <div className="updateButton">
          <button onClick={updateDataImage}>Update Images</button>
        </div>
      </div> */}

      <div className="imagesProduct">
        <ProductImages
          productId={productId}
          productimagesPrev={productimages}
        />
      </div>
    </>
  );
};

export default NewProduct;
