import "./newProduct.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import * as React from "react";

import { useContext } from "react";
import { AuthContext } from "./../../context/AuthContext";
import { updateDoc } from "firebase/firestore";

const ProductImages = ({ productId, productimagesPrev }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // console.log("productimagesPrev: ", productimagesPrev);
  // console.log("productId: ", productId);
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "products", productId), {
        productImages: urls,
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }

    // console.log("Clicked");
    // console.log("Uploaded " + uploaded);
    setUploaded(false);
    setImages([]);
    setUrls([]);
  };

  const handleUpload = () => {
    const promises = [];
    images.map((image) => {
      const name = new Date().getTime() + image.name;

      const storageRef = ref(storage, `images/${name}`);

      const uploadTask = uploadBytesResumable(storageRef, image);

      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
            setUrls((prevState) => [...prevState, urls]);
          });
        }
      );
    });

    Promise.all(promises)
      .then(() => alert("All images uploaded please click Update"))
      .catch((err) => console.log(err));
    // setUrls((prevState) => [productimagesPrev, ...prevState]);
    setUrls(urls.concat(productimagesPrev));
    setUploaded(true);
  };
  // console.log("images: ", images);
  // console.log("urls", urls);

  const handleRemoveURL = (index) => {
    setUrls(urls.filter((url, i) => i !== index));
    setImages(images.filter((image, i) => i !== index));
  };
  return (
    <div>
      <progress value={progress} max="100" /> {progress}%
      <br />
      <br />
      <input type="file" multiple onChange={handleChange} />
      <button onClick={handleUpload}>Upload</button>
      <div className="sendButton">
        <button disabled={uploaded === false} onClick={handleSave}>
          Update
        </button>
      </div>
      <br />
      {/* {urls.map((url, i) => (
        <img
          key={i}
          style={{ width: "500px" }}
          src={url || "http://via.placeholder.com/300"}
          alt="firebase-image"
        />
      ))} */}
      <div>
        {urls.map((url, index) => (
          <div key={index}>
            <img src={url} alt="Updated" />
            <button onClick={() => handleRemoveURL(index)}>Remove Image</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductImages;
