export const userInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "companyName",
    label: "Company Name",
    type: "text",
    placeholder: "Company Name",
  },
  {
    id: "email",
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: "phoneNumber",
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  {
    id: "shippig_address",
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
];
export const userInputsUpdates = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "companyName",
    label: "Company Name",
    type: "text",
    placeholder: "Company Name",
  },

  {
    id: "phoneNumber",
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },

  {
    id: "totalDollar",
    label: "Minimum $ Order",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
  {
    id: "totalLBP",
    label: "Minimum LBP Order",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
  {
    id: "shippig_address",
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
];

export const productInputs = [
  {
    id: "title",
    key: "title",
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
    required: true,
    readOnly: false,
  },

  {
    id: "storeId",
    key: "storeId",
    label: "Store",
    type: "text",
    placeholder: "Store",
    required: false,
    readOnly: true,
  },
  {
    id: "price",
    key: "price",
    label: "Price",
    type: "number",
    step: "0.01",
    placeholder: "100",
    required: true,
    readOnly: false,
  },

  {
    id: "salePrice",
    key: "salePrice",
    label: "Sale Price",
    type: "number",
    step: "0.01",
    placeholder: "Sale Price",
    required: true,
    readOnly: false,
  },
  {
    id: "inStock",
    key: "inStock",
    label: "Stock",
    type: "number",
    placeholder: "in stock",
    required: true,
    readOnly: false,
  },
  // {
  //   id: "productSize",
  //   key: "productSize",
  //   label: "Size",
  //   type: "text",
  //   placeholder: "S, M, L, XL",
  //   required: false,
  //   readOnly: false,
  // },
];
export const newProductInputs = [
  {
    id: "title",
    key: "title",
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
    required: true,
  },

  {
    id: "price",
    key: "price",
    label: "Price",
    type: "number",
    step: "0.01",
    placeholder: "100",
    required: true,
  },

  {
    id: "salePrice",
    key: "salePrice",
    label: "Sale Price",
    type: "number",
    step: "0.01",
    placeholder: "Sale Price",
    required: true,
  },
  {
    id: "inStock",
    key: "inStock",
    label: "Stock",
    type: "number",
    placeholder: "in stock",
    required: true,
  },
  // {
  //   id: "productSize",
  //   key: "productSize",
  //   label: "Size",
  //   type: "text",
  //   placeholder: "S, M, L, XL",
  //   required: false,
  // },
];
export const categoryInputs = [
  {
    id: "title",
    key: "title",
    label: "Title",
    type: "text",
    placeholder: "Category Title",
  },
];
export const bannerInputs = [
  {
    id: "title",
    key: "title",
    label: "Title",
    type: "text",
    placeholder: "Banner Title",
  },
];
export const generatedCodeInputs = [
  {
    id: "code",
    key: "code",
    label: "Code",
    type: "text",
    placeholder: "Code",
  },
  {
    id: "status",
    key: "status",
    label: "Status",
    type: "text",
    placeholder: "Status",
  },
];
export const whatsAppInputs = [
  {
    id: "numberphone",
    key: "numberphone",
    label: "Phone Number",
    type: "text",
    placeholder: "Phone Number",
  },
];
