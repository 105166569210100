import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
// import { useContext } from "react";
// import { AuthContext } from "./../../context/AuthContext";

import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

const New = ({ inputs, title }) => {
  // const { currentUser } = useContext(AuthContext);
  // console.log("currentUser: ", currentUser);
  // let prevUser = currentUser;
  const [file, setFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");
  const [totalDollar, setTotalDollar] = useState("");
  const [totalLBP, setTotalLBP] = useState("");

  const handleChange = (event) => {
    setUserRole(event.target.value);
  };

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, "usersImages/" + name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, imageUrl: downloadURL }));
            setImageUrl(downloadURL);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value.toLowerCase();

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      if (userRole === "vendors") {
        await setDoc(doc(db, userRole, res.user.uid), {
          ...data,
          id: res.user.uid,
          totalDollar: totalDollar,
          totalLBP: totalLBP,
          createdAt: serverTimestamp(),
        });

        try {
          await setDoc(doc(db, "categories", res.user.uid), {
            id: res.user.uid,
            imageUrl: imageUrl,
            title: data.companyName,
            createdAt: serverTimestamp(),
          });
        } catch (err) {
          console.log(err);
        }
      }
      if (userRole === "users") {
        await setDoc(doc(db, userRole, res.user.uid), {
          ...data,
          id: res.user.uid,
          imageUrl: imageUrl,
          tokens: "",
          userCart: [],
          userWish: [],
          createdAt: serverTimestamp(),
        });
      } else {
        await setDoc(doc(db, userRole, res.user.uid), {
          ...data,
          id: res.user.uid,

          createdAt: serverTimestamp(),
        });
      }

      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };
  // console.log(userRole);
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Choose Image:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />

                <div className="detailItem">
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {/* {data.userRole} */}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={userRole}
                      label="userRole"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={"admins"}>Admin</MenuItem>
                      <MenuItem value={"vendors"}>Store</MenuItem>
                      <MenuItem value={"users"}>User</MenuItem>
                    </Select>
                    <FormHelperText>Choose User Role</FormHelperText>
                  </FormControl>
                </div>
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                    required
                  />
                </div>
              ))}
              {userRole === "vendors" ? (
                <>
                  <TextField
                    className="formInput"
                    id="totalDollar"
                    key="totalDollar"
                    label="Total $ Minimum Charge"
                    type="text"
                    placeholder="50"
                    value={totalDollar}
                    onChange={(e) => setTotalDollar(e.target.value)}
                  />
                  <TextField
                    className="formInput"
                    id="totalLBP"
                    key="totalLBP"
                    label="Total LBP Minimum Charge"
                    type="text"
                    placeholder="50"
                    value={totalLBP}
                    onChange={(e) => setTotalLBP(e.target.value)}
                  />
                </>
              ) : null}
              <div className="sendButton">
                <button disabled={per !== null && per < 100} type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
