import "./newProduct.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const EditNumber = ({ inputs, title, fireTable }) => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const whatsAppID = URL[URL.length - 1];
  const [numberphone, setNumberphone] = useState("");
  // const [codeStatus, setCodeStatus] = useState(true);

  // const handleCodeStatus = (event) => {
  //   setCodeStatus(event.target.checked);
  // };
  const [catBan, setcatBan] = useState([]);

  // const handleGenrate = (e) => {
  //   const min = 10000000;
  //   const max = 99999999;
  //   const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  //   setGeneratedCode(randomNumber.toString());
  //   // console.log(randomNumber);
  // };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      const catbanDataFire = query(
        collection(db, fireTable),
        where("id", "==", whatsAppID)
      );

      const querySnapshot = await getDocs(catbanDataFire);
      querySnapshot.forEach((doc) => {
        setcatBan({ id: doc.id, ...doc.data() });
        setNumberphone(doc.data().numberphone);
        // setCodeStatus(doc.data().status);
      });
    };
    fetchProduct();
  }, [whatsAppID, fireTable]);

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, fireTable, catBan.id), {
        numberphone: numberphone,
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput" key={"numberphone"}>
                <label>Phone Number</label>
                <input
                  id="numberphone"
                  type="text"
                  placeholder="96170123456"
                  onChange={(e) => setNumberphone(e.target.value)}
                  required
                  defaultValue={numberphone}
                />
              </div>

              <div className="sendButton">
                <button type="submit">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNumber;
