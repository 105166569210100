import "./list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";

const List = ({ fireTable, title, inHouse }) => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        {/* <Navbar /> */}
        <Datatable fireTable={fireTable} title={title} inHouse={inHouse} />
      </div>
    </div>
  );
};

export default List;
