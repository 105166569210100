import "./notification.scss";
import Sidebar from "../../components/sidebar/Sidebar";

import { DataGrid } from "@mui/x-data-grid";
import { userNotificationColumns } from "../../datatablesource";

import { useEffect, useState } from "react";

import { collection, query, getDocs, orderBy } from "firebase/firestore";

import { db, sendNotification } from "../../firebase";

const Single = () => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const userId = URL[URL.length - 1];

  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [titleUser, setTitleUser] = useState("");
  const [bodyUser, setBodyUser] = useState("");
  const [userDeviceToken, setUserDeviceToken] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const userData = query(
        collection(db, "users"),
        orderBy("createdAt", "desc")
      );

      const querySnapshot = await getDocs(userData);
      let list = [];
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
        // setData({ id: doc.id, ...doc.data() });
      });
      setData(list);
    };

    fetchUser();
  }, [userId]);

  const handleBroadCast = async (e) => {
    e.preventDefault();
    try {
      await sendNotification("/topics/IPITO", title, body);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUserNotification = async (e) => {
    e.preventDefault();
    try {
      await sendNotification(userDeviceToken, titleUser, bodyUser);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        {/* <Navbar /> */}

        <div className="top">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="title">BroadCast Notification</h1>
            <div className="item">
              <div className="details">
                <form onSubmit={handleBroadCast}>
                  <div className="formInput" key="title">
                    <label>Title</label>
                    <input
                      id="title"
                      type="text"
                      placeholder="Enter Title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="formInput" key="body">
                    <label>Body</label>
                    <textarea
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      rows={12}
                      cols={40}
                      placeholder="BroadCast Body"
                      required
                    />
                  </div>

                  <div className="sendButton">
                    <button type="submit">Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="right">
            <h1 className="title">Send Notification to Specific User</h1>
            <div className="item">
              <div className="details">
                <form onSubmit={handleUserNotification}>
                  <div className="formInput" key="userDeviceToken">
                    <label>Device</label>
                    <input
                      id="userDeviceToken"
                      type="text"
                      placeholder="Enter Device ID"
                      onChange={(e) => {
                        setUserDeviceToken(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="formInput" key="titleUser">
                    <label>Title</label>
                    <input
                      id="titleUser"
                      type="text"
                      placeholder="Enter Title"
                      onChange={(e) => {
                        setTitleUser(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="formInput" key="bodyUser">
                    <label>Body</label>
                    <textarea
                      value={bodyUser}
                      onChange={(e) => setBodyUser(e.target.value)}
                      rows={10}
                      cols={40}
                      placeholder="Body For Specific User"
                      required
                    />
                  </div>

                  <div className="sendButton">
                    <button type="submit">Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <h1 className="title">Order Items</h1>

          <DataGrid
            className="datagrid"
            rows={data}
            columns={userNotificationColumns}
            pageSize={6}
            rowsPerPageOptions={[6]}
            style={{ height: "58vh" }}
            // getRowId={(ordersDetails) => ordersDetails.productId}
            // checkboxSelection
            components={{
              NoRowsOverlay: () => (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <h3>
                    {data.length > 0 ? (
                      <span>Loading...</span>
                    ) : (
                      <span>no rows</span>
                    )}
                  </h3>
                </div>
              ),
            }}
          />

          {/* <List /> */}
        </div>
      </div>
    </div>
  );
};

export default Single;
