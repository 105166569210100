import React from "react";
import errorImage from "./../images/error.png";

const NoInternet = () => {
  return (
    <>
      <img src={errorImage} alt={errorImage} width="1500" height="800" />
    </>
  );
};

export default NoInternet;
