import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
import { Link } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { ordersColumns, productColumns } from "../../datatablesource";

import { useEffect, useState } from "react";

import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

import { db } from "../../firebase";

const Single = ({ fireTable }) => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const userId = URL[URL.length - 1];

  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [imageLink, setimageLink] = useState("");

  let prodOrderTable;
  let columnTable;
  let tableTitle;
  let chartTitle;

  if (fireTable === "users") {
    columnTable = ordersColumns;
    prodOrderTable = "orders";
    tableTitle = "Last Transactions";
    chartTitle = "User Spending ( Last 6 Months)";
  } else if (fireTable === "vendors") {
    columnTable = productColumns;
    prodOrderTable = "products";
    tableTitle = "Products";
    chartTitle = "Orders ( Last 6 Months)";
  }

  // console.log(fireTable);
  useEffect(() => {
    const fetchUser = async () => {
      const userData = query(
        collection(db, fireTable),
        where("id", "==", userId)
      );

      const querySnapshot = await getDocs(userData);
      querySnapshot.forEach((doc) => {
        setData({ id: doc.id, ...doc.data() });
        setStoreName(doc.data().name);
        doc.data().imageUrl
          ? setimageLink(doc.data().imageUrl)
          : setimageLink(
              "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
            );
      });
    };

    // On Time Call Order
    // const fetchData = async () => {
    //   let odersList = [];
    //   const orderData = query(
    //     collection(db, prodOrderTable),
    //     where("userId", "==", userId),
    //     orderBy("orderDate", "desc")
    //   );
    //   try {
    //     const querySnapshot = await getDocs(orderData);
    //     querySnapshot.forEach((doc) => {
    //       odersList.push({ id: doc.id, ...doc.data() });
    //     });
    //     setOrders(odersList);
    //     // console.log(odersList);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // const fetchData = async () => {
    //   const userData = query(
    //     collection(db, "users"),
    //     where("id", "==", userId)
    //   );

    //   const querySnapshot = await getDocs(userData);
    //   querySnapshot.forEach((doc) => {
    //     setData(doc.data());
    //   });

    // const ordersData = query(
    //   collection(db, "orders"),
    //   where("userId", "==", userId)
    // );

    // const querySnapshotOrders = await getDocs(ordersData);
    // querySnapshotOrders.forEach((doc) => {
    //   setOrders(doc.data());
    // });
    // };

    // const userData = query(collection(db, "users"), where("id", "==", userId));

    // const fetchUser = () => {
    //   const data = getDocs(userData);
    //   setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    // };

    // Real Time Orders
    // const fetchOrdersUsers = onSnapshot(
    //   collection(db, "orders"),
    //   where("userId", "==", userId),
    //   (snapShot) => {
    //     let odersList = [];
    //     snapShot.docs.forEach((doc) => {
    //       odersList.push({ id: doc.id, ...doc.data() });
    //     });
    //     setOrders(odersList);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );

    fetchUser();
    // fetchData();

    // fetchOrdersUsers();
  }, [userId, fireTable]);

  useEffect(() => {
    const fetchData = async () => {
      let odersList = [];
      let orderData;
      if (prodOrderTable === "products") {
        orderData = query(
          collection(db, prodOrderTable),
          where("storeUid", "==", userId),
          orderBy("createdAt", "desc")
        );
      } else if (prodOrderTable === "orders") {
        orderData = query(
          collection(db, prodOrderTable),
          where("userId", "==", userId),
          orderBy("orderDate", "desc")
        );
      }

      try {
        const querySnapshot = await getDocs(orderData);
        querySnapshot.forEach((doc) => {
          odersList.push({ id: doc.id, ...doc.data() });
        });
        setOrders(odersList);
        // console.log(odersList);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [userId, fireTable, storeName, prodOrderTable]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={params.row.id.toString()}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        {/* <Navbar /> */}

        <div className="top">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="title">Information</h1>
            <div className="item">
              <img src={imageLink} alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">{data.name}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phoneNumber}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{data.shippig_address}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Company:</span>
                  <span className="itemValue">{data.companyName}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart
              aspect={3 / 1}
              title={chartTitle}
              fireTable={fireTable}
              userId={fireTable === "users" ? userId : storeName}
            />
          </div>
        </div>

        <div className="bottom">
          <h1 className="title">{tableTitle}</h1>

          <DataGrid
            className="datagrid"
            rows={orders}
            columns={
              fireTable === "vendors"
                ? columnTable
                : columnTable.concat(actionColumn)
            }
            pageSize={6}
            rowsPerPageOptions={[6]}
            experimentalFeatures={{ newEditingApi: true }}
            style={{ height: "58vh" }}
            // checkboxSelection
            components={{
              NoRowsOverlay: () => (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <h3>
                    {data.length > 0 ? (
                      <span>Loading...</span>
                    ) : (
                      <span>no rows</span>
                    )}
                  </h3>
                </div>
              ),
            }}
          />

          {/* <List /> */}
        </div>
      </div>
    </div>
  );
};

export default Single;
