import "./newProduct.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const EditCode = ({ inputs, title, fireTable }) => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const catbanId = URL[URL.length - 1];
  const [generatedCode, setGeneratedCode] = useState("");
  const [codeStatus, setCodeStatus] = useState(true);

  const handleCodeStatus = (event) => {
    setCodeStatus(event.target.checked);
  };
  const [catBan, setcatBan] = useState([]);

  const handleGenrate = (e) => {
    const min = 10000000;
    const max = 99999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    setGeneratedCode(randomNumber.toString());
    // console.log(randomNumber);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      const catbanDataFire = query(
        collection(db, fireTable),
        where("id", "==", catbanId)
      );

      const querySnapshot = await getDocs(catbanDataFire);
      querySnapshot.forEach((doc) => {
        setcatBan({ id: doc.id, ...doc.data() });
        setGeneratedCode(doc.data().code);
        setCodeStatus(doc.data().status);
      });
    };
    fetchProduct();
  }, [catbanId, fireTable]);

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, fireTable, catBan.id), {
        code: generatedCode,
        status: codeStatus,
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <Button
              variant="outlined"
              onClick={() => {
                handleGenrate();
              }}
            >
              Re-Generate Code
            </Button>
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput" key={"code"}>
                <label>Code</label>
                <input
                  id="code"
                  type="text"
                  placeholder="Generate Code"
                  onChange={(e) => setGeneratedCode(e.target.value)}
                  required
                  defaultValue={generatedCode}
                />
              </div>

              <Box sx={{ display: "flex", gap: 4 }}>
                <FormControlLabel
                  className="formInput"
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={codeStatus}
                      onChange={handleCodeStatus}
                    />
                  }
                  label="Enable"
                  labelPlacement="start"
                />
              </Box>
              <div className="sendButton">
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCode;
