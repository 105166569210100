import React from "react";
import "./invoiceTemp.scss";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import numeral from "numeral";
import QRcode from "react-qr-code";

const Invoice = () => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const orderId = URL[URL.length - 1];

  const [data, setData] = useState([]);
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [orderDate, setorderDate] = useState("");

  const invoiceBarCode = `https://admin.para-mart.com/invoice/${orderId}`;

  const handlePrint = () => {
    window.print();
  };
  useEffect(() => {
    const fetchUser = async () => {
      const userData = query(
        collection(db, "orders"),
        where("orderId", "==", orderId)
      );

      const querySnapshot = await getDocs(userData);
      querySnapshot.forEach((doc) => {
        setData({ id: doc.id, ...doc.data() });
        setOrdersDetails(doc.data().orderDetails);
        setStoreName(doc.data().orderDetails[0].productStoreId);
        setorderDate(doc.data().orderDate.toDate().toString());
        // setOrderStatus(doc.data().orderStatus);
      });
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);
  //   console.log("User: ", user);

  return (
    <>
      <link
        href="//netdna.bootstrapcdn.com/bootstrap/3.1.0/css/bootstrap.min.css"
        rel="stylesheet"
        id="bootstrap-css"
      />
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="invoice-title">
              <h2>Invoice</h2>
              <h5 className="pull-right">Order # {orderId}</h5>
            </div>
            <hr />
            <div className="row">
              <div className="col-xs-6 ">
                <address>
                  <strong>Shipped To:</strong>
                  <br />
                  {data.userName}
                  <br />
                  {data.companyName}
                  <br />
                  {data.phoneNumber}
                  <br />
                  {data.userShippingAddress}
                </address>
              </div>

              <div className="col-xs-6 text-right">
                <address>
                  <strong>Billed From:</strong>
                  <br />
                  {storeName.toUpperCase()}
                  <br />
                  <QRcode
                    size={256}
                    level="Q"
                    style={{
                      height: "auto",
                      margin: "0 auto",
                      maxWidth: 90,
                      width: "100%",
                      padding: "10px",
                    }}
                    value={invoiceBarCode}
                    viewBox={`0 0 256 256`}
                  />
                </address>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-6 ">
                <address>
                  <strong>Order Date:</strong>
                  <br />
                  {orderDate}
                  <br />
                  <br />
                </address>
              </div>

              <div className="col-xs-6 text-right">
                <address>
                  <strong>Payment Method:</strong>
                  <br />
                  Cash On Deliver
                  <br />
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">
                  <strong>Order summary</strong>
                </h3>
              </div>
              <div className="panel-body">
                <div className="table-responsive">
                  <table className="table table-condensed">
                    <thead>
                      <tr>
                        <td>
                          <strong>№</strong>
                        </td>
                        <td>
                          <strong>Item</strong>
                        </td>
                        <td className="text-center">
                          <strong>Size</strong>
                        </td>
                        <td className="text-center">
                          <strong>Price</strong>
                        </td>
                        <td className="text-center">
                          <strong>Quantity</strong>
                        </td>
                        <td className="text-right">
                          <strong>Total</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {/* foreach ($order->lineItems as $line) or some such thing here */}
                      {ordersDetails.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.productTitle}</td>

                          {item.productSizePrice != null &&
                          item.productSizePrice ? (
                            <td className="text-center">
                              {item.productSizePrice}
                            </td>
                          ) : (
                            <td className="text-center"> - </td>
                          )}

                          {item.productIsDollar ? (
                            <td className="text-center">
                              $ {numeral(item.productPrice).format("0,0.00")}
                            </td>
                          ) : (
                            <td className="text-center">
                              LBP {numeral(item.productPrice).format("0,0.00")}
                            </td>
                          )}
                          {/* <td className="text-center">{item.productPrice}</td> */}

                          {item.productAdvertise != null &&
                          item.productAdvertise ? (
                            <td className="text-center">
                              {item.productQuantity} - Offer
                            </td>
                          ) : (
                            <td className="text-center">
                              {item.productQuantity}
                            </td>
                          )}
                          <td className="text-right">
                            {numeral(item.productTotal).format("0,0.00")}
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                        <td>BS-200</td>
                        <td className="text-center">$10.99</td>
                        <td className="text-center">1</td>
                        <td className="text-right">$10.99</td>
                      </tr> */}

                      <tr>
                        <td className="thick-line" />
                        <td className="thick-line" />
                        <td className="thick-line" />
                        <td className="thick-line" />
                        <td className="thick-line text-center">
                          <strong>Total</strong>
                        </td>
                        <td className="thick-line text-right">
                          <strong>
                            $ {numeral(data.totalPrice).format("0,0.00")}{" "}
                          </strong>
                        </td>
                      </tr>

                      <tr>
                        <td className="no-line" />
                        <td className="no-line" />
                        <td className="no-line" />
                        <td className="no-line" />
                        <td className="no-line text-center">
                          <strong>Total</strong>
                        </td>
                        <td className="no-line text-right">
                          <strong>
                            LBP {numeral(data.totalLBP).format("0,0.00")}{" "}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <button
            className="pull-right hide-on-print sendButtonInvoice"
            onClick={handlePrint}
          >
            Print Invoice
          </button>
        </div>
      </div>
    </>
  );
};

export default Invoice;
