import numeral from "numeral";

export const userColumns = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "user",
    headerName: "User",
    width: 220,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={
              params.row.imageUrl
                ? params.row.imageUrl
                : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
            }
            alt="avatar"
          />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "companyName",
    headerName: "Company",
    width: 220,
  },
  {
    field: "email",
    headerName: "Email",
    width: 220,
  },

  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 140,
  },
  {
    field: "shippig_address",
    headerName: "Shipping Address",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return (
          <p>
            {params.row.createdAt.toDate().toString()}
            {/* {params.row.orderDate.toDate().getDate() +
                  "-" +
                  params.row.orderDate.toDate().getMonth() +
                  "-" +
                  params.row.orderDate.toDate().getFullYear()} */}
          </p>
        );
      } else {
        return <p>{""}</p>;
      }
    },
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];
export const vendorsColumns = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "user",
    headerName: "User",
    width: 220,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={
              params.row.imageUrl
                ? params.row.imageUrl
                : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
            }
            alt="avatar"
          />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "companyName",
    headerName: "Company",
    width: 220,
  },
  {
    field: "email",
    headerName: "Email",
    width: 220,
  },

  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 140,
  },
  {
    field: "totalDollar",
    headerName: "Minimum $ Order",
    width: 250,
    renderCell: (params) => {
      return <p>{numeral(params.row.totalDollar).format("0,0.00")}</p>;
    },
  },
  {
    field: "totalLBP",
    headerName: "Minimum LBP Order",
    width: 250,
    renderCell: (params) => {
      return <p>{numeral(params.row.totalLBP).format("0,0.00")}</p>;
    },
  },
  {
    field: "shippig_address",
    headerName: "Shipping Address",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return (
          <p>
            {params.row.createdAt.toDate().toString()}
            {/* {params.row.orderDate.toDate().getDate() +
                  "-" +
                  params.row.orderDate.toDate().getMonth() +
                  "-" +
                  params.row.orderDate.toDate().getFullYear()} */}
          </p>
        );
      } else {
        return <p>{""}</p>;
      }
    },
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];
export const userNotificationColumns = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "user",
    headerName: "User",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.imageUrl} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "tokens",
    headerName: "Device ID",
    width: 650,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 140,
  },
  {
    field: "shippig_address",
    headerName: "Shipping Address",
    width: 250,
  },
];
export const productColumns = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "title",
    headerName: "Title",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.imageUrl} alt="avatar" />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "productCategoryName",
    headerName: "Category",
    width: 180,
  },
  {
    field: "storeId",
    headerName: "Store",
    width: 200,
  },
  {
    field: "price",
    headerName: "Price",
    width: 160,
    renderCell: (params) => {
      return <p>{numeral(params.row.price).format("0,0.00")}</p>;
    },
  },

  {
    field: "isDollar",
    headerName: "Currency",
    width: 140,
    renderCell: (params) => {
      return (
        <div className="cellStatus">
          <div
            className={`btn 
                                ${
                                  params.row.isDollar === true
                                    ? "btn-dollar"
                                    : "btn-grey"
                                }`}
          >
            {params.row.isDollar === true ? "$" : "LBP"}
          </div>
        </div>
      );
    },
  },
  {
    field: "isOnSale",
    headerName: "On Sale",
    width: 180,
    // editable: true,
    renderCell: (params) => {
      return (
        <div className="cellStatus">
          <div
            className={`btn 
                                ${
                                  params.row.isOnSale === true
                                    ? "btn-success"
                                    : "btn-warning"
                                }`}
            // onClick={() => handleChangeisApproved(params.row)}
          >
            {params.row.isOnSale === true ? "On Sale" : "No Sale"}
          </div>
        </div>
      );
    },
  },
  {
    field: "salePrice",
    headerName: "Sale Price",
    width: 180,
    renderCell: (params) => {
      return <p>{numeral(params.row.salePrice).format("0,0.00")}</p>;
    },
  },
  {
    field: "inStock",
    headerName: "In Stock",
    width: 180,
    renderCell: (params) => {
      return (
        <div className="cellStatus">
          <div
            className={`btn ${
              params.row.inStock >= 10 ? "btn-success" : "btn-warning"
            }`}
          >
            {params.row.inStock > 0 ? params.row.inStock : "Out Of Stock"}
          </div>
        </div>
      );
    },
  },
  {
    field: "productAdvertise",
    headerName: "Advertisemant",
    width: 180,
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return (
          <p>
            {params.row.createdAt.toDate().toString()}
            {/* {params.row.orderDate.toDate().getDate() +
                  "-" +
                  params.row.orderDate.toDate().getMonth() +
                  "-" +
                  params.row.orderDate.toDate().getFullYear()} */}
          </p>
        );
      } else {
        return <p>{""}</p>;
      }
    },
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];

export const ordersColumns = [
  { field: "id", headerName: "id", width: 150 },
  {
    field: "title",
    headerName: "Image",
    width: 100,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.imageUrl} alt="avatar" />
          {params.row.title}
        </div>
      );
    },
  },

  {
    field: "storeId",
    headerName: "Store",
    width: 200,
  },

  {
    field: "userName",
    headerName: "Orderd By",
    width: 140,
  },
  {
    field: "totalLBP",
    headerName: "Total LBP",
    width: 140,
    renderCell: (params) => {
      return <p>{numeral(params.row.totalLBP).format("0,0.00")}</p>;
    },
  },
  {
    field: "totalPrice",
    headerName: "Total $",
    width: 180,
    renderCell: (params) => {
      return <p>{numeral(params.row.totalPrice).format("0,0.00")}</p>;
    },
  },
  {
    field: "orderItemCounts",
    headerName: "Items",
    width: 120,
  },

  {
    field: "orderDate",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.orderDate != null) {
        return (
          <p>
            {params.row.orderDate.toDate().toString()}
            {/* {params.row.orderDate.toDate().getDate() +
                  "-" +
                  params.row.orderDate.toDate().getMonth() +
                  "-" +
                  params.row.orderDate.toDate().getFullYear()} */}
          </p>
        );
      } else {
        return <p>{""}</p>;
      }
    },
  },

  {
    field: "orderStatus",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.orderStatus}`}>
          {params.row.orderStatus}
        </div>
      );
    },
  },
];
export const ordersDetailsColumns = [
  // { field: "id", headerName: "id", width: 100 },
  { field: "productId", headerName: "id", width: 150 },
  {
    field: "title",
    headerName: "Title",
    width: 220,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.productImageUrl}
            alt="avatar"
          />
          {params.row.productTitle}
        </div>
      );
    },
  },

  {
    field: "productStoreId",
    headerName: "Store",
    width: 200,
  },
  {
    field: "productCategoryName",
    headerName: "Category",
    width: 160,
  },

  {
    field: "productPrice",
    headerName: "Price",
    width: 140,
    renderCell: (params) => {
      if (params.row.productIsDollar === true) {
        return (
          <div className="cellWithStatus">
            {numeral(params.row.productPrice).format("0,0.00")} {"$"}
          </div>
        );
      }
      return (
        <div className="cellWithStatus">
          {numeral(params.row.productPrice).format("0,0.00")} {"LBP"}
        </div>
      );
    },
  },
  {
    field: "productQuantity",
    headerName: "Quantity",
    width: 140,
  },
  {
    field: "productSizePrice",
    headerName: "Size",
    width: 140,
  },
  {
    field: "productAdvertise",
    headerName: "Advertisement",
    width: 140,
  },

  {
    field: "productTotal",
    headerName: "Total",
    width: 160,
    renderCell: (params) => {
      if (params.row.productIsDollar === true) {
        return (
          <div className="cellWithStatus">
            {numeral(params.row.productTotal).format("0,0.00")} {"  $"}
          </div>
        );
      }
      return (
        <div className="cellWithStatus">
          {numeral(params.row.productTotal).format("0,0.00")} {"  LBP"}
        </div>
      );
    },
  },
  // {
  //   field: "productDescription",
  //   headerName: "Items",
  //   width: 120,
  // },
];

export const categoriesColumns = [
  // { field: "id", headerName: "id", width: 100 },
  { field: "id", headerName: "id", width: 180 },
  {
    field: "title",
    headerName: "Title",
    width: 220,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.imageUrl} alt="avatar" />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return <p>{params.row.createdAt.toDate().toString()}</p>;
      } else {
        return <p>{""}</p>;
      }
    },
  },
];

export const bannersColumns = [
  // { field: "id", headerName: "id", width: 100 },
  { field: "id", headerName: "id", width: 180 },
  {
    field: "title",
    headerName: "Title",
    width: 220,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.imageUrl} alt="avatar" />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return <p>{params.row.createdAt.toDate().toString()}</p>;
      } else {
        return <p>{""}</p>;
      }
    },
  },
];
export const generatedCodeColumns = [
  // { field: "id", headerName: "id", width: 100 },
  { field: "id", headerName: "id", width: 180 },
  {
    field: "code",
    headerName: "Code",
    width: 220,
  },
  {
    field: "status",
    headerName: "Status",
    width: 220,
    renderCell: (params) => {
      return (
        <div className="cellStatus">
          <div
            className={`btn 
                                ${
                                  params.row.status === true
                                    ? "btn-success"
                                    : "btn-warning"
                                }`}
          >
            {params.row.status === true ? "Enabled" : "Disabled"}
          </div>
        </div>
      );
    },
  },

  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return <p>{params.row.createdAt.toDate().toString()}</p>;
      } else {
        return <p>{""}</p>;
      }
    },
  },
];
export const phoneNumberColumns = [
  // { field: "id", headerName: "id", width: 100 },
  { field: "id", headerName: "id", width: 180 },
  {
    field: "numberphone",
    headerName: "Phone Number",
    width: 220,
  },

  {
    field: "createdAt",
    headerName: "Created",
    width: 188,
    sortable: true,
    renderCell: (params) => {
      if (params.row.createdAt != null) {
        return <p>{params.row.createdAt.toDate().toString()}</p>;
      } else {
        return <p>{""}</p>;
      }
    },
  },
];
