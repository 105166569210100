import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";

import { db } from "../../firebase";

const data = [
  { name: "January" },
  { name: "February" },
  { name: "March" },
  { name: "April" },
  { name: "May" },
  { name: "June" },
  { name: "July" },
  { name: "August" },
  { name: "September" },
  { name: "October" },
  { name: "November" },
  { name: "December" },
];

const Chart = ({ aspect, title, fireTable, userId }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const today = new Date();
      // for (let index = 0; index < today.getMonth() + 1; index++) {
      for (let index = 5; index >= 0; index--) {
        const thisMonth = new Date(
          new Date().setMonth(today.getMonth() - index)
        );
        const prevMonth = new Date(
          new Date().setMonth(today.getMonth() - (index + 1))
        );

        var stateMonth = thisMonth.getMonth();

        var prevMonthQuery = "";
        if (fireTable === "users") {
          prevMonthQuery = query(
            collection(db, "orders"),
            where("orderDate", "<=", thisMonth),
            where("orderDate", ">", prevMonth),
            where("userId", "==", userId)
          );
        } else if (fireTable === "vendors") {
          prevMonthQuery = query(
            collection(db, "orders"),
            where("orderDate", "<=", thisMonth),
            where("orderDate", ">", prevMonth),
            where("storeId", "==", userId)
          );
        } else {
          prevMonthQuery = query(
            collection(db, "orders"),
            where("orderDate", "<=", thisMonth),
            where("orderDate", ">", prevMonth)
          );
        }

        const prevMonthData = await getDocs(prevMonthQuery);

        list.push({
          name: data[stateMonth].name,
          Total: prevMonthData.docs.length,
        });
      }
      setOrders(list);
    };
    fetchData();
  }, [fireTable, userId]);

  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={orders}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
