import "./newProduct.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

import { v4 } from "uuid";

const NewProduct = ({ inputs, title, fireTable }) => {
  const [generatedCode, setGeneratedCode] = useState("");
  const [codeStatus, setCodeStatus] = useState(true);
  const navigate = useNavigate();

  const generatedCodeUid = v4();

  const handleCodeStatus = (event) => {
    setCodeStatus(event.target.checked);
  };

  const handleGenrate = (e) => {
    const min = 10000000;
    const max = 99999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    setGeneratedCode(randomNumber.toString());
    // console.log(randomNumber);
  };
  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, fireTable, generatedCodeUid), {
        id: generatedCodeUid,
        code: generatedCode,
        status: codeStatus,
        createdAt: serverTimestamp(),
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <Button
              variant="outlined"
              onClick={() => {
                handleGenrate();
              }}
            >
              Generate Code
            </Button>
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput" key={"code"}>
                <label>Code</label>
                <input
                  id="code"
                  type="text"
                  placeholder="Generate Code"
                  value={generatedCode}
                  onChange={(e) => setGeneratedCode(e.target.value)}
                  required
                />
              </div>

              <Box sx={{ display: "flex", gap: 4 }}>
                <FormControlLabel
                  className="formInput"
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={codeStatus}
                      onChange={handleCodeStatus}
                    />
                  }
                  label="Enable"
                  labelPlacement="start"
                />
              </Box>

              <div className="sendButton">
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
