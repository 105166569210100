import "./singleview.scss";
import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
// import { Link } from "react-router-dom";
import numeral from "numeral";
import QRcode from "react-qr-code";

import { DataGrid } from "@mui/x-data-grid";
import { ordersDetailsColumns } from "../../datatablesource";

import { useEffect, useState } from "react";

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

import { db, sendNotification } from "../../firebase";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Single = () => {
  const CURRENT_URL = window.location.pathname;
  const URL = CURRENT_URL.split("/");
  const userId = URL[URL.length - 1];
  const navitage = useNavigate();

  const [data, setData] = useState([]);
  const [ordersDetails, setOrdersDetails] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [orderDate, setorderDate] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [tokenUserId, setTokenUserId] = useState("");
  const [userDeviceToken, setUserDeviceToken] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceBarCode, setinvoiceBarCode] = useState("");

  const handleChange = (event) => {
    setOrderStatus(event.target.value);
  };

  const handleInvoice = (e) => {
    e.preventDefault();
    navitage(`/invoice/${data.orderId}`);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const userData = query(
        collection(db, "orders"),
        where("orderId", "==", userId)
      );

      const querySnapshot = await getDocs(userData);
      querySnapshot.forEach((doc) => {
        setData({ id: doc.id, ...doc.data() });
        setOrdersDetails(doc.data().orderDetails);
        setStoreName(doc.data().orderDetails[0].productStoreId);
        setorderDate(doc.data().orderDate.toDate().toString());
        setTokenUserId(doc.data().userId);
        setinvoiceBarCode(
          `https://admin.para-mart.com/invoice/${doc.data().orderId}`
        );
      });
    };

    const getUserToken = async () => {
      const usertokenData = query(
        collection(db, "users"),
        where("id", "==", tokenUserId)
      );

      const querySnapshot = await getDocs(usertokenData);
      querySnapshot.forEach((doc) => {
        setUserDeviceToken(doc.data().tokens);
      });
    };

    fetchUser();
    getUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, orderStatus]);

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "orders", userId), {
        orderStatus: orderStatus,
      });
      await sendNotification(
        userDeviceToken,
        `Your Order from ${storeName} is ${orderStatus}`,
        "Click here for more details"
      );
      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        {/* <Navbar /> */}

        <div className="top">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="title">Order Details</h1>
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">No:</span>
                  <span className="itemValue">{data.orderId}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Date:</span>
                  <span className="itemValue">{orderDate}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status:</span>
                  <span className="itemValue">{data.orderStatus}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Total:</span>
                  <span className="itemValue">
                    {numeral(data.totalPrice).format("0,0.00")}{" "}
                  </span>
                  <span className="itemValue"> $</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Total:</span>
                  <span className="itemValue">
                    {numeral(data.totalLBP).format("0,0.00")}{" "}
                  </span>
                  <span className="itemValue"> LBP</span>
                </div>
                <form onSubmit={handleAdd}>
                  <div className="detailItem">
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {data.orderStatus}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={orderStatus}
                        label="orderStatus"
                        onChange={handleChange}
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"In Progress"}>In Progress</MenuItem>
                        <MenuItem value={"Delivered"}>Delivered</MenuItem>
                        <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                      </Select>
                      <FormHelperText>Change Order Status</FormHelperText>
                    </FormControl>
                  </div>
                  <div className="sendButton">
                    <button type="submit">Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="right">
            <h1 className="title">Shipping Info</h1>
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">Name:</span>
                  <span className="itemValue">{data.userName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Company Name:</span>
                  <span className="itemValue">{data.companyName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phoneNumber}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Shipping Address:</span>
                  <span className="itemValue">{data.userShippingAddress}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">From:</span>
                  <span className="itemValue">{storeName}</span>
                </div>
                <div>
                  <form onSubmit={handleInvoice}>
                    <div className="sendButton">
                      <button type="submit">Print Invoice</button>
                    </div>
                  </form>
                </div>

                <div
                  style={{
                    height: "auto",
                    margin: "1 auto",
                    maxWidth: 120,
                    width: "100%",
                    padding: "16px",
                  }}
                >
                  <QRcode
                    size={256}
                    level="Q"
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={invoiceBarCode}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <h1 className="title">Order Items</h1>

          <DataGrid
            className="datagrid"
            rows={ordersDetails}
            columns={ordersDetailsColumns}
            pageSize={6}
            rowsPerPageOptions={[6]}
            style={{ height: "58vh" }}
            getRowId={(ordersDetails) => uuidv4()}
            // checkboxSelection
            components={{
              NoRowsOverlay: () => (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <h3>
                    {data.length > 0 ? (
                      <span>Loading...</span>
                    ) : (
                      <span>no rows</span>
                    )}
                  </h3>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Single;
