import "./table.scss";

import { DataGrid } from "@mui/x-data-grid";
import { ordersColumns } from "../../datatablesource";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";

import { db } from "../../firebase";

const List = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let odersList = [];
      const orderData = query(
        collection(db, "orders"),
        // where("userId", "==", userId),
        orderBy("orderDate", "desc"),
        limit(30)
      );
      try {
        const querySnapshot = await getDocs(orderData);
        querySnapshot.forEach((doc) => {
          odersList.push({ id: doc.id, ...doc.data() });
        });
        setOrders(odersList);
        // console.log(odersList);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`orders/${params.row.id.toString()}`}
              // {...params.row.id.toString()}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <DataGrid
      className="datagrid"
      rows={orders}
      columns={ordersColumns.concat(actionColumn)}
      pageSize={6}
      rowsPerPageOptions={[6]}
      experimentalFeatures={{ newEditingApi: true }}
      style={{ height: "58vh" }}
      // checkboxSelection
      components={{
        NoRowsOverlay: () => (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <h3>
              {orders.length > 0 ? (
                <span>Loading...</span>
              ) : (
                <span>no rows</span>
              )}
            </h3>
          </div>
        ),
      }}
    />
  );
};

export default List;
